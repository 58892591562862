import './index.scss'

import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'

import fullpage from 'fullpage.js'
import 'fullpage.js/dist/fullpage.min.css'

(function ($) {
  $.fn.numberRock = function (options) {
    var defaults = {
      lastNumber: 100,
      duration: 3000,
      easing: 'swing'
    };
    var opts = $.extend({}, defaults, options);

    $(this).animate({
      num: "numberRock",
    }, {
      duration: opts.duration,
      easing: opts.easing,
      complete: function () {
        // console.log("success");
      },
      step: function (a, b) {
        $(this).html(parseInt(b.pos * opts.lastNumber));
      }
    });
  }
})(jQuery);

!(() => {
  indexfullpage()
  bannerSwiper()
  madieSwiper()
})()

//首页全屏滚动
function indexfullpage() {
  new fullpage('#fullpage', {
    // licenseKey: 'YOUR_KEY_HERE',
    anchors: ['Home', 'ConvergenceMedia', 'ChengfaIntroduction', 'LinesOfBusiness', 'PartyBuilding', 'IncorruptibleChengfa', 'ChengfaLink'],
    scrollingSpeed: 400,
    navigation: true,
    verticalCentered: false,
    navigationPosition: 'left',
    css3: true,
    resize: true,
    scrollBar: true,
    afterLoad: function (anchorLink, el) {
      console.log('afterLoad', el.index)
      let i = el.index;
      //fullpage第二屏开始头部样式改变，左侧aside显示
      if (i >= 1) {
        $('.header .cheader,.copyright').addClass('other-page');
        $('.aside-cont').fadeIn()
      }
    },
    onLeave: function (origin, destination, direction) {
      console.log('onLeave', origin.index, destination.index, direction)
      let i = destination.index
      //离开每一屏时隐藏左侧aside搜索框
      $('.search-ct').removeClass('icon-guanbi').addClass('icon-search')
      $('.aside-search-from').css('width', '0px');
      $('.aside-search-from input').blur()

      //上滚到第一屏时改变header,隐藏左侧aside 
      if (i == 0 && direction == 'up') {
        $('.header .cheader,.copyright').removeClass('other-page');
        $('.aside-cont').fadeOut()
      }

      //上滚到第六屏时取消第六屏加载动画
      if (i == 5 && direction == 'up') {
        $('.honest').addClass('six-page')
      } else {
        $('.honest').removeClass('six-page')
      }
      //第一屏和最后一屏隐藏右侧copyright滚动提示
      if (i == 0 || i == 6) {
        $('.scroll-text,.scroll-icon').fadeOut()
      } else {
        $('.scroll-text,.scroll-icon').fadeIn()
      }
      //第五屏时修改左侧搜索样式
      if (i == 4) {
        $('.aside').addClass('five-page');
      } else {
        $('.aside').removeClass('five-page');
      }
      //最后一屏时修改左侧搜索样式
      if (i == 6) {
        $('.aside').addClass('last-page');
      } else {
        $('.aside').removeClass('last-page');
      }

      if (i == 1) {
        $('.aside').removeClass('bg-4').addClass('bg-1')
      } else if (i == 2) {
        $('.aside').removeClass('bg-4').addClass('bg-1')
        let _txt1 = $(".counter1").attr('data-val');
        let _txt2 = $(".counter2").attr('data-val');
        let _txt3 = $(".counter3").attr('data-val');
        let _txt4 = $(".counter4").attr('data-val');
        $(".counter1").numberRock({
          lastNumber: _txt1,
          duration: 2000,
          easing: 'swing',
        });
        $(".counter2").numberRock({
          lastNumber: _txt2,
          duration: 2000,
          easing: 'swing',
        });
        $(".counter3").numberRock({
          lastNumber: _txt3,
          duration: 2000,
          easing: 'swing',
        });
        $(".counter4").numberRock({
          lastNumber: _txt4,
          duration: 2000,
          easing: 'swing',
        });
      } else if (i == 3) {
        $('.aside').removeClass('bg-4').addClass('bg-1')
      } else if (i == 4) {
        $('.aside').removeClass('bg-1').addClass('bg-4')
      } else if (i == 5) {
        $('.aside').removeClass('bg-4').addClass('bg-1')
      }
    }

  });
}
//banner切换
function bannerSwiper() {
  let swiper = new Swiper('.bannerSwiper', {
    //  effect: 'fade',
    //    fadeEffect: {
    //      crossFade: true
    //    },
    autoplay: {
      delay: 6000,
      disableOnInteraction: false
    },
    speed: 800,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      transitionStart() {
        let i = this.realIndex
        $('.index-pages span').html('0' + (i + 1))
      }
    }
  });
}

// aside search
$('.search-ct').on('click', function () {
  if ($(this).hasClass('icon-search')) {
    $(this).removeClass('icon-search').addClass('icon-guanbi')
    $('.aside-search-from').css('width', '300px');
    $('.aside-search-from input').focus()
  } else {
    $(this).removeClass('icon-guanbi').addClass('icon-search')
    $('.aside-search-from').css('width', '0px');
    $('.aside-search-from input').blur()
  }
})

//融媒体swiper
function madieSwiper() {
  let swiper = new Swiper('.media-center-swiper', {
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });
}

// 集团介绍 video
$('.video-play').on('click', function () {
  $(this).find('.iconfont').hide()
  $('.video').addClass('active').find('#video').get(0).play();
})